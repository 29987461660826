export const homeObjOne = {
    id: 'aboutus',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Detailed Residential Home Inspections',
    headline: '',
    description: "We pride ourselves on providing you the best results possible by using the best tools and knowledge in the industry. Gates Whitmer was fully trained and certified by the Inspection Certification Association in early 2021 and has a Batchelor of Science in Computer Information Systems. Once completing the 200 hour home inspection training program and the NC Licensing exam, he has been tasked with helping you know as much as possible about your next home.",
    buttonLabel: 'Schedule Inspection',
    imgStart: true,
    img: require('../../images/detail.jpg'),
    alt: 'Inspection',
    dark: false,
    primary: false,
    darkText: true
};

export const homeObjThree = {
    id: 'foragents',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'For Agents',
    headline: 'We Inspect!',
    description: "Our Detail Residential Home Inspections are designed to give your clients a complete overview of a prospective property. We inspect all structures accessible within the property giving them a detailed report on the findings we observe. These observations include (but not limited to) the foundation, structure, exterior, roof, interior, hvac system(s), appliances, electrical system(s), plumbing system, and more. If there is anything you would like us to look at or test for your clients we are just a call or email away.",
    buttonLabel: 'Call Now',
    imgStart: true,
    img: require('../../images/agent_1.jpg'),
    alt: 'Inspection',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id: 'yourreport',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Your Report',
    headline: 'Your Way',
    description: "Reports will be provided to you in a timely manner upon completion of the detailed inspection. Normally within 24 hours and in most cases you will have your report emailed to you the same day as the inspection. Our reports will be provided to you through a client portal for review and can be easily downloaded in .pdf format.",
    buttonLabel: 'Demo - Coming Soon!',
    imgStart: true,
    img: require('../../images/web_report.png'),
    alt: 'Report',
    dark: false,
    primary: false,
    darkText: true
};
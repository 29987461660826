import styled from "styled-components";
import {Link} from 'react-router-dom';


export const Container = styled.div`
    height: 1760px;
    position: relative;
    background: #0c0c0c;
    display: flex;
    flex-direction: column;
    /*padding: 0 20px;*/
    z-index: 1;
    
    

    :before{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), 
        linear-gradient(180deg , rgba(0,0,0,0.2) 0%, transparent 100%);
        
        z-index: 2;
    }
`;

export const ContactUsBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
    position: fixed;
    filter: blur(10px);
`;

export const Nav = styled.div`
    background: #08192e;
    height: 80px;
    width: 100%;
    margin-top: -80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    padding: 0 24px;
    top: 0;
    z-index: 5;
    

    @media screen and (max-width: 400px) {
        height: 60px;
    }
`;

export const Icon = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 5px 0px 5px 35px;
    
    @media screen and (max-width: 480px) {
        margin-right: 16px;
        margin-top: 8px;
    }
`;

export const IconImg = styled.img`
    width: 84px;
    
    @media screen and (max-width: 896px) {
        width: 64px;
    }
`;

export const NavBtn = styled.div`
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    @media screen and (max-width: 896px) {
        height: 50%;
        width: 50%;
    }
`

export const FormContent = styled.div`
    max-width: 1500px;
    margin-top: 95px;
    margin-left: 150px;
    display: flex;
    flex-wrap: wrap;
    
    grid-template-columns: 1fr 1fr 1fr;
    justify-content:center;
    align-items: center;
    grid-gap: 24px;
    padding: 10px 50px;
    top:0;
    z-index: 4;
    

    @media screen and (max-width: 1001px) {
        grid-template-columns: 1fr 1fr;
        margin-left: 25px;
    };

    @media screen and ( max-width: 1001px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

export const Form = styled.form`
    background: #e1ecfa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 520px;
    width: 380px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.10);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`;

export const FormImg = styled.img`
    
    height: 250px;
    width: 330px;
    margin-bottom: 15px;
    
`;

export const FormH1 = styled.div`
    margin-top: 15px;
    margin-bottom: 13px;
    color: #08192e;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
`;

export const FormDesc = styled.div`
    margin-bottom: 15px;
    color: #08192e;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    text-wrap: wrap;
`;



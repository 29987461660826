import React, {useState} from 'react';
import Video from '../../video/video.mp4';
import Img from '../../images/logo.png';

import { Button } from '../ButtonElements';
import { 
  HeroContainer, 
  HeroBg, 
  VideoBg, 
  HeroContent, 
  HeroImgWrap,
  HeroImg,
  HeroH1, 
  HeroP, 
  HeroBtnWrapper, 
  ArrowForward, 
  ArrowRight            
} from './HeroElements';

const HeroSection = () => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer >
        <HeroBg>
            <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
        </HeroBg>
        <HeroContent>
          <HeroImgWrap>
            <HeroImg src={Img} alt='GW Inspections, LLC'></HeroImg>
          </HeroImgWrap>
          <HeroH1>Make Your Next Home Purchase Easy</HeroH1>
          <HeroP>
            Schedule your next home's inspection and enjoy
            not having to second guess anything.
          </HeroP>
          <HeroBtnWrapper>
            <Button 
              to='/contactus'
              onMouseEnter={onHover} 
              onMouseLeave={onHover} 
              primary='true'
              dark='true'
            >
              Schedule Inspection {hover ? <ArrowForward /> : <ArrowRight />}
            </Button>
          </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;

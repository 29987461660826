import React from 'react';
import Video from '../../video/video.mp4';
import Img from '../../images/logo.png';
import {
    Container,
    FormWrap,
    ContactUsBg,
    VideoBg,
    Icon,
    IconImg,
    FormContent,
    Form,
    FormH1
} from './ContactusElements';

const ContactUs = () => {
    return (
        <>
            <Container>
                <ContactUsBg>
                    <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
                </ContactUsBg>
                <FormWrap>
                    <Icon to="/">
                        <IconImg src={Img} alt='GW Inspections, LLC'></IconImg>
                    </Icon>
                        <FormContent>
                            <Form action="/">
                                <FormH1 href="tel:+2525679969">Gates Whitmer</FormH1>
                                <FormH1 href="tel:+2525679969">252-567-9969</FormH1>
                                <FormH1 href="mailto:gwinspectionsllc@gmail.com">gwinspectionsllc@gmail.com</FormH1>
                            </Form>
                        </FormContent>
                </FormWrap>
            </Container>
        </>
    );
};

export default ContactUs;
import React from 'react';
import AllPagesPDFViewer from '../components/PdfStandards';
import samplePDF from "./GWI_Sample.pdf";

import "./sample_report.css";

const SampleReport = () => {

    return (
    
        <>
            
            
            
            <div className="all-page-container">
                <AllPagesPDFViewer pdf={samplePDF} />
            </div>
    
        </>
    );
};

export default SampleReport;
import React, {useState} from 'react';
import Video from '../../video/video.mp4';
import Img from '../../images/logo.png';
import Structure from '../../images/structure.jpg';
import Exterior from '../../images/exterior.jpg';
import Roofing from '../../images/roofing.jpg';
import Plumbing from '../../images/plumbing.jpg';
import Electrical from '../../images/electrical.jpg';
import Hvac from '../../images/hvac.jpg';
import Interior from '../../images/interior.jpg';
import Insulation from '../../images/insulation.jpg';
import Appliances from '../../images/appliances.jpg';
import { Button } from '../ButtonElements';

import {
    Container,
    Nav,
    ContactUsBg,
    VideoBg,
    Icon,
    IconImg,
    NavBtn,
    FormContent,
    Form,
    FormH1,
    FormImg,
    FormDesc
} from './HomeInspectionsElements';

const HomeInspections = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
    setHover(!hover)
    }
    
    

    return (
        <>
            <Container>
                <ContactUsBg>
                    <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
                </ContactUsBg>
                    <Nav>
                        <Icon to="/">
                            <IconImg src={Img} alt='GW Inspections, LLC'></IconImg>
                        </Icon>
                        <NavBtn>
                            <Button 
                                to='/contactus'
                                onMouseEnter={onHover} 
                                onMouseLeave={onHover} 
                                primary='true'
                                dark='true'
                            >
                                Schedule Inspection 
                            </Button>
                        </NavBtn>
                    </Nav>
                    <FormContent>
                        <Form>
                            <FormImg src={Structure} alt='Structural Components'></FormImg>
                            <FormH1>Structural Components</FormH1>
                            <FormDesc>Inspect and describe Foundation, Floor structure, 
                                Wall structure, Columns/Piers, Ceiling structure, 
                                and Roof structure. Crawling in crawlspaces and reporting all findings.</FormDesc>
                        </Form>
                        <Form>
                            <FormImg src={Exterior} alt='Exterior'></FormImg>
                            <FormH1>Exterior</FormH1>
                            <FormDesc>Inspect and describe Wall cladding, flashings, 
                                trims, entry doors, garage doors and operators, windows, 
                                decks, balconies, stoops, steps, areaways, porches, patios, 
                                handrails, walkways, and retaining walls. Vegetation, grading, 
                                and drainage are inspected with the respect only to their 
                                effect on the home.</FormDesc>
                        </Form>
                        <Form>
                            <FormImg src={Roofing} alt='Roofing'></FormImg>
                            <FormH1>Roofing</FormH1>
                            <FormDesc>Inspect and describe roof coverings, roof drainage 
                                systems, flashings, all roof penetrations (ex. skylights, 
                                chimneys, and plumbing vents), and signs of leaks or 
                                excess moisture on roofing components. We try our best 
                                to physically climb and walk roofs where possible.</FormDesc>
                        </Form>
                        <Form>
                            <FormImg src={Plumbing} alt='Plumbing'></FormImg>
                            <FormH1>Plumbing</FormH1>
                            <FormDesc>Inspect and describe water supply, distribution piping, 
                                supports and insulation where used, faucets throughout for 
                                functional flow, drain-waste-vent systems, hot water systems, 
                                fuel storage and distribution systems, and sump pumps where 
                                used. We also check for leaks and cross-connections on the 
                                plumbing system and report all findings.</FormDesc>
                        </Form>
                        <Form>
                            <FormImg src={Electrical} alt='Electrical'></FormImg>
                            <FormH1>Electrical</FormH1>
                            <FormDesc>Inspect and describe electrical service conductors (wires), 
                                their amperage, voltage, material, and location(s). We inspect 
                                all grounding equipment, ground fault circuit interrupters, main
                                overcurrent devices, interiors of panelboards unless reported 
                                unsafe, branch circuit conductors and their overcurrent devices, 
                                fans, switches, receptacles, smoke detectors, and installed 
                                carbon monoxide detectors.</FormDesc>
                        </Form>
                        <Form>
                            <FormImg src={Hvac} alt='HVAC'></FormImg>
                            <FormH1>HVAC Systems</FormH1>
                            <FormDesc>Inspect and describe heating and air conditioning systems 
                                which include listing energy source, equipment, and distribution 
                                type used. We use normal operating controls to operate both the 
                                heating and cooling systems depending on weather conditions, 
                                and open readily accessible panels used for routine maintenance. 
                                This helps while inspecting the distribution systems, fans, 
                                pumps, dampers, ducts, piping, supports, insulation, air filters, 
                                registers, radiators, fan coil units, and convectors.</FormDesc>
                        </Form>
                        <Form>
                            <FormImg src={Interior} alt='Interior'></FormImg>
                            <FormH1>Interior</FormH1>
                            <FormDesc>Inspect all floors, walls, ceilings, steps, stairways, 
                                balconies, railings, doors, windows, counters, and all built-in 
                                cabinets. We report any signs of water penetration into the home 
                                or signs of abnormal or harmful condensation on the interior 
                                components.</FormDesc>
                        </Form>
                        <Form>
                            <FormImg src={Insulation} alt='Insulation and Ventilation'></FormImg>
                            <FormH1>Insulation and Ventilation</FormH1>
                            <FormDesc>Inspect and describe insulation in unfinished areas of the 
                                home or absence of insulation against finished surfaces. Inspect 
                                all insulation, vapor retarders, ventilation of attics and foundation 
                                areas, kitchen, bathroom, and laundry venting systems, and we 
                                inspect readily accessible attic ventilation fans and thermostatic 
                                controls.</FormDesc>
                        </Form>
                        <Form>
                            <FormImg src={Appliances} alt='Appliances'></FormImg>
                            <FormH1>Appliances</FormH1>
                            <FormDesc>Inspect and operate installed dishwashers (complete cycle), 
                                range(s), cook top(s), installed oven(s), trash compactor(s), 
                                garbage disposal(s), ventilation equipment (range hood(s)), 
                                and installed microwave oven(s).</FormDesc>
                        </Form>
                    </FormContent>
            </Container>
        </>
    );
};

export default HomeInspections;

/*import { 
  NavBg,
  HomeContainer,
  HomeH1           
} from './HomeInspectionsElements';

const HomeInspections = () => {
  return (
    
    <HomeContainer>
      <HomeH1>
        HomeInspectionsPage
      </HomeH1>
    </HomeContainer>
  );
};

export default HomeInspections;*/
import React from 'react';
import ContactUs from '../components/Contactus';

const ContactusPage = () => {
return (
    <>
        <ContactUs />    
    </>
    );
};

export default ContactusPage;

import React from 'react';
import AllPagesPDFViewer from '../components/PdfStandards';
import standardsPDF from "./NCHILB201810.pdf";

import "./standards_of_practice.css";

const PdfView = () => {

    return (
    
        <>
    
            <h4>North Carolina Standards of Practice</h4>
            <div className="all-page-container">
                <AllPagesPDFViewer pdf={standardsPDF} />
            </div>
    
        </>
    );
};

export default PdfView;
import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
import ContactusPage from './pages/contactus';
import HomeInspections from './pages/homeinspections';
import SampleReport from './pages/sample_report';
import PdfView from './pages/standards_of_practice';
import ScrollToTop from './components/ScrollTopTop';

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Home/>} exact/>
        <Route path='/contactus' element={<ContactusPage />} exact/>
        <Route path='/homeinspections' element={<HomeInspections />} exact/>
        <Route path='/standards_of_practice' element={<PdfView />} exact/>
        <Route path='/sample_report' element={<SampleReport />} exact/>
      </Routes>
    </Router>
  );
}

export default App;

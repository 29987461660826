import React from 'react';
import Icon1 from '../../images/svg-1.svg';
import Icon2 from '../../images/svg-3.svg';
import Icon3 from '../../images/svg-2.svg';

import { 
    ServicesContainer,
    ServicesH1,
    ServicesWrapper,
    ServicesCardGw,
    ServicesCardPh,
    ServicesCard,
    ServicesIcon,
    ServicesH2,
    ServicesP
} from './ServiceElements';

const Services = () => {
    return (
        <ServicesContainer id='services'>
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCardGw to='/homeinspections'>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Detailed Home Inspections</ServicesH2>
                    <ServicesP>We use the latest home inspection software to deliver you a visual, modern home inspection report that explains our findings in a way you can understand! Please select to learn more!</ServicesP>
                </ServicesCardGw>
                <ServicesCardPh to='/contactus'>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Phase Inspections</ServicesH2>
                    <ServicesP>We help you know your new home before, during, and after the build process! Please call for pricing. </ServicesP>
                </ServicesCardPh>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>And More</ServicesH2>
                    <ServicesP>This section is under construction as we continue to grow! Mold and Radon testing coming soon!</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
};

export default Services;

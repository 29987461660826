import React, {useState, useEffect} from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaLinkedin, FaFacebook, FaInstagram } from 'react-icons/fa';

import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLinkRoute,
    FooterLink,
    FooterLinkPdf,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink
} from './FooterElements'

const Footer = () => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = ()=> {
        if(window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);
    
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Resources</FooterLinkTitle>
                                <FooterLink to='aboutus'
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact='true'
                                            offset={-80}
                                >
                                    About Us
                                </FooterLink>
                                <FooterLink to='services'
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}
                                >
                                    Services
                                </FooterLink>
                                <FooterLink to='yourreport'
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact='true'
                                        offset={-80}
                                >
                                    Your Report
                                </FooterLink>
                                <FooterLink to='foragents'
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}
                                >
                                    For Agents
                                </FooterLink>
                                <FooterLinkPdf to='/standards_of_practice'>Standards of Practice</FooterLinkPdf>
                                <FooterLinkRoute to='/sample_report'>Sample Home Inspection</FooterLinkRoute>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Home Owner CheckList</FooterLinkTitle>
                                <FooterLink to="/">HVAC Filters</FooterLink>
                                <FooterLink to="/aboutus">Shingles/Roof Penetrations</FooterLink>
                                <FooterLink to="/aboutus">Qualking/Seals on Windows</FooterLink>
                                <FooterLink to="/aboutus">Water Flow Around Property</FooterLink>
                                <FooterLink to="/aboutus">Grout/Caulking in Bathrooms</FooterLink>
                                <FooterLink to="/aboutus">Vent Hood Filters</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            NCHILB #5237
                        </SocialLogo>
                        <WebsiteRights>GW Inspections, LLC © {new Date().getFullYear()} All Rights Reserved.</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink to="/" target="_blank" aria-label="Linkedin">
                                <FaLinkedin />
                            </SocialIconLink>
                            <SocialIconLink href="//www.facebook.com/GWInspectionsLLC" target="_blank" aria-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink to="/" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
};

export default Footer;

import React from 'react';
import HomeInspections from '../components/HomeInspections';


const HomeInspectionsPage = () => {
  
  return (
    
    <>
    
      <HomeInspections/>
    
    </>
  );
};

export default HomeInspectionsPage;